import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import t from "../i18n";
import destinations from "../data/destinatnions.json"
import { graphql, PageProps } from "gatsby";
import { TypeImage, TypeLocale } from "../types";
import Image from "../components/ui/Image";
import { createLabel, sanitizedHTML } from "../utilities";
import HeadMeta from "../components/layout/HeadMeta";

type DestinationsData = {
    allFile: { images: TypeImage[] }
}
type DestinationsContext = {
    locale: TypeLocale
}

const Destinations: FC<PageProps<DestinationsData, DestinationsContext>> = ( { pageContext, data } ) => {
    const { locale: l } = pageContext
    return (
        <Layout noContainer noContentPadding className={ "px--md" }>
            <HeadMeta title={ t( "Destinations" ) }/>
            <h1 className={ "text--center sr-only" }>{ t( "Destinations" ) }</h1>
            <div className={ "destinations container--big" }>
                { Object.keys( destinations ).map( ( value ) => {
                    return (
                        <div key={ value }>
                            <h2 className={ "text--center text--accent py--md sticky top--header z--2 bg--body mt--md" }>{ t( createLabel( value ) ) }</h2>
                            { destinations[value as keyof typeof destinations].map( destination => {
                                const image = data.allFile.images.find( ( img: TypeImage ) => img.relativePath === `destinations/${ destination.slug }.jpg` );
                                return (
                                    <article key={ destination.id } className={ "relative destinations__single bg--bgDimmed md:min-h--v75 mt--md md:flex al__it--fl-end jf__cnt--fl-end" }>
                                        { image && <Image data={ image.childImageSharp.gatsbyImageData } alt={ destination.slug } className={ "md:absolute md:h--100 md:inset--0 h--hero w--100" }/> }
                                        <div className={ "md:p--xl md:max-w--60ch w--100" }>
                                            <div className={ "destinations__info round p--xl" }>
                                                <h3 className={ "text--accent h2" }>{ destination.name[l] }</h3>
                                                <div dangerouslySetInnerHTML={ sanitizedHTML( destination.description[l] ) }/>
                                            </div>
                                        </div>
                                    </article>
                                )
                            } ) }
                        </div>
                    )
                } ) }
            </div>
        </Layout>
    )
}

export default Destinations

export const query = graphql`{
    allFile(
        sort: {fields: name}
        filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {in: ["destinations"]}}
    ) {
        images: nodes {
            relativePath
            childImageSharp {
                original {
                    src
                }
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
}`
